import { actualizarDatosContacto, getUsuarioByID } from '@app/api/usuario.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const doGetUsuarioByID = createAsyncThunk('usuario/getUsuarioByID', async (id_usuario: any) =>
  getUsuarioByID(id_usuario).then((res) => {
    return res;
  }),
);

export const doActualizarDatosContacto = createAsyncThunk('usuario/actualizarDatosContacto', async (data: any) =>
  actualizarDatosContacto(data).then((res) => {
    return res;
  }),
);

const usuarioSlice = createSlice({
  name: 'usuario',
  reducers: {},
  initialState: undefined,
});

export default usuarioSlice.reducer;
