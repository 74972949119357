import React from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from '../DashboardPage.styles';
import { ExampleComponent } from '@app/components/pages/home/ExampleComponent/ExampleComponent';

const HomePage: React.FC = () => {
  const { isDesktop } = useResponsive();

  const { t } = useTranslation();

  const desktopLayout = (
    <Row>
      <S.LeftSideCol xl={16} xxl={17}>
        <Row gutter={[30, 30]}>
          <ExampleComponent />
        </Row>
      </S.LeftSideCol>
    </Row>
  );

  const mobileAndTabletLayout = (
    <Row gutter={[20, 20]}>
      <S.LeftSideCol xl={16} xxl={17}>
        <Row gutter={[30, 30]}>
          <ExampleComponent />
        </Row>
      </S.LeftSideCol>
    </Row>
  );

  return (
    <>
      <PageTitle>{t('Telemedicina')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default HomePage;
