import { atom } from 'recoil';

export const gebAtom = atom<string>({
  key: 'geb-atom',
  default: ' ',
});

export const getAtom = atom<string>({
  key: 'get-atom',
  default: ' ',
});
