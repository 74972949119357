import React from 'react';
//import { useTranslation } from 'react-i18next';
import { CategoryComponents } from '@app/components/header/components/HeaderSearch/HeaderSearch';

interface SearchResultsProps {
  results: CategoryComponents[];
}

export const SearchResults: React.FC<SearchResultsProps> = ({}) => {
  //const { t } = useTranslation();
  return <p>1</p>;
};
