import React from 'react';
import { Button as AntdButton } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

export interface ButtonProps {
  buttonText: string;
}

const Button: React.FC<{ buttonText: string }> = ({ buttonText }) => {
  return (
    <div style={{ position: 'relative', margin: '40px auto', left: '10%', textAlign: 'center', zIndex: 2 }}>
      <AntdButton type="primary" className="ant-btn-primary" icon={<SaveOutlined />}>
        {buttonText}
      </AntdButton>
    </div>
  );
};

export default Button;
