export enum ActividadLigeraType {
  Caminar = 'Caminar',
  Limpar = 'Limpiar la casa',
  Compras = 'Ir de compras',
}

export enum ActividadModeradaType {
  Gimnasio = 'Levantar pesas',
  Correr = 'Correr',
}

export enum ActividadIntensaType {
  Futbol = 'Futbol',
  Nadar = 'Nadar',
}
