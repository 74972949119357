import React from 'react';
import { ConfigProvider } from 'antd';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './AppRouter';
import { useLanguage } from './hooks/useLanguage';
//import { useAutoNightMode } from './hooks/useAutoNightMode';
//import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { setTheme } from './api/slices/themeSlice';
import { setNightMode } from './api/slices/nightModeSlice';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  //usePWA();

  //useAutoNightMode();
  const dispatch = useAppDispatch();
  dispatch(setTheme('light'));
  dispatch(setNightMode(false));
  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].light} />
      <GlobalStyle />
      <ConfigProvider locale={language === 'en' ? enUS : deDe}>
        <AppRouter />
      </ConfigProvider>
    </>
  );
};

export default App;
