import React from 'react';
import { CategoryComponents } from '@app/components/header/components/HeaderSearch/HeaderSearch';

interface SearchFilterProps {
  data: CategoryComponents[] | null;
  isVisible: boolean;
  children: (filteredResults: CategoryComponents[]) => React.ReactNode;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({}) => {
  return <p>1</p>;
};
