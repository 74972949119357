import React from 'react';
import { Button as AntdButton } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';

export interface ButtonPrintProps {
  buttonText: string;
}

const ButtonPrint: React.FC<ButtonPrintProps> = ({ buttonText }) => {
  return (
    <div style={{ position: 'relative', margin: '40px auto', left: '15%', textAlign: 'center', zIndex: 2 }}>
      <AntdButton
        type="primary"
        className="ant-btn-primary"
        icon={<PrinterOutlined />}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {buttonText}
      </AntdButton>
    </div>
  );
};

export default ButtonPrint;
