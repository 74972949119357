import React, { useState } from 'react';
import { Table } from 'antd';
import equivalentes from './diccionarioEquivalentes';

interface EquivalenteNutricional {
  kcal: number;
  proteinas: number;
  lipidos: number;
  carbohidratos: number;
}

interface GrupoAlimenticio {
  nombre: string;
  equivalente?: EquivalenteNutricional;
}

interface DiccionarioEquivalentes {
  gruposAlimenticios: GrupoAlimenticio[];
}

const data = [
  {
    key: '1',
    grupoAlimenticio: 'Verduras',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '2',
    grupoAlimenticio: 'Frutas',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '3',
    grupoAlimenticio: 'Cereales y tubérculos sin grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '4',
    grupoAlimenticio: 'Cereales y tubérculos con grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '5',
    grupoAlimenticio: 'Leguminosas',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '6',
    grupoAlimenticio: 'Alimentos de origen animal muy bajo aporte de grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '7',
    grupoAlimenticio: 'Alimentos de origen animal bajo aporte de grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '8',
    grupoAlimenticio: 'Alimentos de origen animal moderado aporte de grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '9',
    grupoAlimenticio: 'Alimentos de origen animal alto aporte de grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '10',
    grupoAlimenticio: 'Leche descremada',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '11',
    grupoAlimenticio: 'Leche semidescremada',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '12',
    grupoAlimenticio: 'Leche entera',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '13',
    grupoAlimenticio: 'Leche con azúcar',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '14',
    grupoAlimenticio: 'Aceites y grasas sin proteína',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '15',
    grupoAlimenticio: 'Aceites y grasas con proteína',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '16',
    grupoAlimenticio: 'Azucares sin grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '17',
    grupoAlimenticio: 'Azucares con grasa',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '18',
    grupoAlimenticio: 'Grasa con proteína',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '19',
    grupoAlimenticio: 'Grasa sin proteína',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '20',
    grupoAlimenticio: 'Suplemento de proteína',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
  {
    key: '21',
    grupoAlimenticio: 'Bebidas alcohólicas',
    kcal: '',
    proteinas: '',
    lipidos: '',
    carbohidratos: '',
  },
];

const NutritionalTable = () => {
  const initialDataWithTotal = [
    ...data,
    {
      key: 'total',
      grupoAlimenticio: 'Total',
      kcal: '0',
      proteinas: '0',
      lipidos: '0',
      carbohidratos: '0',
    },
  ];

  const [datos, setDatos] = useState(initialDataWithTotal);

  const calcularValoresNutricionales = (nombreGrupo: string, numeroEquivalentes: number) => {
    const grupo = equivalentes.gruposAlimenticios.find((g) => g.nombre === nombreGrupo);
    if (grupo && grupo.equivalente) {
      return {
        kcal: grupo.equivalente.kcal * numeroEquivalentes,
        proteinas: grupo.equivalente.proteinas * numeroEquivalentes,
        lipidos: grupo.equivalente.lipidos * numeroEquivalentes,
        carbohidratos: grupo.equivalente.carbohidratos * numeroEquivalentes,
      };
    }
    return { kcal: 0, proteinas: 0, lipidos: 0, carbohidratos: 0 };
  };

  // Función para manejar el cambio en el campo de entrada de equivalentes
  const handleEquivalentesChange = (index: number, newValue: number) => {
    const grupoAlimenticio = datos[index].grupoAlimenticio;
    const valoresNutricionales = calcularValoresNutricionales(grupoAlimenticio, newValue);

    setDatos((datosActuales) => {
      const nuevosDatos = datosActuales.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            kcal: valoresNutricionales.kcal.toString(),
            proteinas: valoresNutricionales.proteinas.toString(),
            lipidos: valoresNutricionales.lipidos.toString(),
            carbohidratos: valoresNutricionales.carbohidratos.toString(),
          };
        }
        return item;
      });

      // Recalcula y actualiza los totales aquí
      const totalesCalculados = nuevosDatos.reduce(
        (acum, item) => {
          if (item.key !== 'total') {
            acum.kcal += parseFloat(item.kcal) || 0;
            acum.proteinas += parseFloat(item.proteinas) || 0;
            acum.lipidos += parseFloat(item.lipidos) || 0;
            acum.carbohidratos += parseFloat(item.carbohidratos) || 0;
          }
          return acum;
        },
        { kcal: 0, proteinas: 0, lipidos: 0, carbohidratos: 0 },
      );

      // Actualiza la fila "Total"
      const totalIndex = nuevosDatos.findIndex((item) => item.key === 'total');
      nuevosDatos[totalIndex] = {
        ...nuevosDatos[totalIndex],
        kcal: totalesCalculados.kcal.toString(),
        proteinas: totalesCalculados.proteinas.toString(),
        lipidos: totalesCalculados.lipidos.toString(),
        carbohidratos: totalesCalculados.carbohidratos.toString(),
      };

      return nuevosDatos;
    });
  };

  const columns = [
    {
      title: 'Grupo alimenticio',
      dataIndex: 'grupoAlimenticio',
      key: 'grupoAlimenticio',

      onCell: () => {
        return {
          style: {
            background: 'lightgray',
            fontWeight: 'bold',
          },
        };
      },
      render: (text: string, record: any, index: number) => {
        if (index === datos.length - 1) {
          return {
            children: <div style={{ textAlign: 'center' }}>{`${text}`}</div>,
            props: {
              colSpan: 2,
            },
          };
        }
        return text;
      },
    },
    {
      title: 'Equivalentes',
      dataIndex: 'equivalentes',
      key: 'equivalentes',
      render: (text: string, record: any, index: number) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [showWarning, setShowWarning] = useState(false);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [warningMessage, setWarningMessage] = useState('');
        if (record.key === 'total') {
          // Fila 'Total,' no permite ingresar texto
          return {
            props: {
              colSpan: 0,
            },
          };
        }

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          // Verificar que el valor ingresado sea un número entre 0 y 100
          const newValue = e.target.value;
          if (newValue === '0' || (newValue.match(/^[1-9][0-9]?$|^100$/) && newValue !== '00')) {
            setShowWarning(false); // Oculta la advertencia si el valor es válido
            // Actualizar los valores nutricionales
            handleEquivalentesChange(index, Number(newValue));
          } else {
            setShowWarning(true); // Muestra la advertencia
            setWarningMessage('El valor debe ser un número entre 0 y 100.');
            e.target.value = ''; // Elimina el valor no válido
          }
        };

        return (
          <div>
            <input
              type="text"
              value={text}
              style={{
                padding: '8px',
                width: '100%',
                boxSizing: 'border-box',
                border: '1px solid #ccc',
                borderRadius: '4px',
                fontSize: '16px',
                backgroundColor: 'transparent', // Establece el fondo del cuadro de texto como transparente
                textAlign: 'center', // Centra el texto horizontalmente
                lineHeight: '2', // Centra el texto verticalmente
              }}
              onChange={handleInputChange}
            />
            {showWarning && <div style={{ color: 'red' }}>{warningMessage}</div>}
          </div>
        );
      },
    },
    {
      title: 'Kcal',
      dataIndex: 'kcal',
      key: 'kcal',
    },
    {
      title: 'Proteínas',
      dataIndex: 'proteinas',
      key: 'proteinas',
    },
    {
      title: 'Lípidos',
      dataIndex: 'lipidos',
      key: 'lipidos',
    },
    {
      title: 'Carbohidratos',
      dataIndex: 'carbohidratos',
      key: 'carbohidratos',
      render: (text: string, record: any, index: number) => {
        if (index === 22) {
          return {
            props: {
              colSpan: 1,
            },
          };
        }
        return text;
      },
    },
  ];

  return <Table columns={columns} dataSource={datos} bordered size="large" pagination={false} scroll={{ y: 600 }} />;
};

export default NutritionalTable;
