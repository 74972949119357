import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SMEPage from '@app/pages/SME/SMEPage';
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
const IndexLayout = React.lazy(() => import('@app/components/layouts/index/IndexLayout'));
import LoginPage from '@app/pages/auth/LoginPage';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
import HomePage from '@app/pages/home/HomePage';
import RequireAuth from './RequireAuth';
import Calculadora from './components/pages/calculadora/calculadora';
import RegistroPage from './pages/auth/RegistroPage';
import ConfirmarRegistroPage from './pages/auth/ConfirmarRegistroPage';
import DatosUsuarioPage from './pages/auth/DatosUsuarioPage';
import DatosContactoPage from './pages/auth/DatosContactoPage';

const Logout = React.lazy(() => import('./Logout'));
export const HOME = '/';

const HomePaged = withLoading(HomePage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const IndexLayoutFallback = withLoading(IndexLayout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={HOME} element={protectedLayout}>
          <Route index element={<HomePaged />} />
          <Route path="/calculadora" element={<Calculadora />} />
          <Route path="/sme" element={<SMEPage />} /> {/* Nueva ruta para SMEPage */}
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="registro" element={<RegistroPage />} />
          <Route path="confirmar-registro" element={<ConfirmarRegistroPage />} />
          <Route path="datos-usuario/:code" element={<DatosUsuarioPage />} />
          <Route path="datos-contacto/:id_usuario/:tipo_usuario" element={<DatosContactoPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="/index" element={<IndexLayoutFallback />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
