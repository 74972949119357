import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
//import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';

export const ProfileDropdown: React.FC = () => {
  //const { isTablet } = useResponsive();
  const user = useAppSelector((state) => state.user.user);
  return user ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        {/*<Col>
          <Avatar src={user.imgUrl} alt="User" shape="circle" size={40} />
  </Col>*/}
        <Col>
          <H6>{`${user.correo}`}</H6>
        </Col>
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};
