import { getCatalogoEstados, getCatalogoMunicipios } from '@app/api/catalogo.api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const doGetCatalogoEsatados = createAsyncThunk('catalogo/getCatalogoEstados', async () =>
  getCatalogoEstados().then((res) => {
    return res;
  }),
);

export const doGetCatalogoMunicipios = createAsyncThunk('catalogo/getCatalogoMunicipios', async (id_estado: any) =>
  getCatalogoMunicipios(id_estado).then((res) => {
    return res;
  }),
);

const catalogosSlice = createSlice({
  name: 'catalogos',
  reducers: {},
  initialState: undefined,
});

export default catalogosSlice.reducer;
