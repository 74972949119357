import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doActualizarDatosUsuario, doGetUserFromCode, doSignUp } from '@app/api/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './DatosUsuarioForm.styles';
import { DatePicker } from 'antd';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Loading } from '@app/components/common/Loading';

let initValues = {
  id_usuario: 0,
  tipo_usuario: '',
  cat_tipo_usuario: {
    nombre: '',
  },
};

export const DatosUsuarioForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(true);
  const { t } = useTranslation();
  const { code } = useParams();

  useEffect(() => {
    dispatch(doGetUserFromCode(code))
      .unwrap()
      .then((res) => {
        initValues = res.data;
        if ([null, undefined].includes(res.data)) {
          setLoadingPage(true);
          return;
        }
        console.log(res.data);
        setLoadingPage(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  }, []);

  const handleSubmit = (values: any) => {
    values.id_usuario = initValues.id_usuario;
    values.tipo_usuario = initValues.cat_tipo_usuario.nombre;
    setLoading(true);
    dispatch(doActualizarDatosUsuario(values))
      .unwrap()
      .then((res) => {
        console.log(res);
        notificationController.success({
          message: t('Operación exitosa.'),
          description: t('Se ha guarado correctamente la información'),
        });
        navigate('/auth/datos-contacto/' + res.data.id_usuario + '/' + res.data.tipo_usuario);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('Datos personales')}</S.Title>
        <Auth.FormItem
          name="nombre"
          label={t('Nombre')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Nombre')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="apellido_paterno"
          label={t('Apellido paterno')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Apellido paterno')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="apellido_materno"
          label={t('Apellido materno')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Apellido materno')} />
        </Auth.FormItem>

        <BaseForm.Item name="sexo" label={t('Sexo')} rules={[{ required: true, message: t('common.requiredField') }]}>
          <Select placeholder={t('Sexo')}>
            <Option value="M">{t('Masculino')}</Option>
            <Option value="F">{t('Femenino')}</Option>
          </Select>
        </BaseForm.Item>

        <BaseForm.Item
          name="fecha_nacimiento"
          label={t('Fecha de nacimiento')}
          rules={[{ required: true, message: t('Fecha de nacimiento es requerida') }]}
        >
          <DatePicker format="YYYY-MM-DD" placeholder="Selecciona fecha" />
        </BaseForm.Item>

        <Auth.FormItem
          name="correo"
          label={t('Correo electrónico')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('Correo electrónico')} readOnly />
        </Auth.FormItem>

        <Auth.FormItem
          name="estatura"
          label={t('Estatura')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Estatura')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Guardar')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
