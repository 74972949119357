const equivalentes = {
  gruposAlimenticios: [
    {
      nombre: 'Verduras',
      equivalente: {
        kcal: 25,
        proteinas: 2,
        lipidos: 0,
        carbohidratos: 4,
      },
    },
    {
      nombre: 'Frutas',
      equivalente: {
        kcal: 60,
        proteinas: 0,
        lipidos: 0,
        carbohidratos: 15,
      },
    },
    {
      nombre: 'Cereales y tubérculos sin grasa',
      equivalente: {
        kcal: 70,
        proteinas: 2,
        lipidos: 0,
        carbohidratos: 15,
      },
    },
    {
      nombre: 'Cereales y tubérculos con grasa',
      equivalente: {
        kcal: 115,
        proteinas: 2,
        lipidos: 5,
        carbohidratos: 15,
      },
    },

    {
      nombre: 'Leguminosas',
      equivalente: {
        kcal: 120,
        proteinas: 8,
        lipidos: 1,
        carbohidratos: 20,
      },
    },

    {
      nombre: 'Alimentos de origen animal muy bajo aporte de grasa',
      equivalente: {
        kcal: 40,
        proteinas: 8,
        lipidos: 1,
        carbohidratos: 20,
      },
    },
    {
      nombre: 'Alimentos de origen animal bajo aporte de grasa',
      equivalente: {
        kcal: 55,
        proteinas: 8,
        lipidos: 3,
        carbohidratos: 0,
      },
    },
    {
      nombre: 'Alimentos de origen animal moderado aporte de grasa',
      equivalente: {
        kcal: 75,
        proteinas: 7,
        lipidos: 5,
        carbohidratos: 0,
      },
    },
    {
      nombre: 'Alimentos de origen animal alto aporte de grasa',
      equivalente: {
        kcal: 100,
        proteinas: 7,
        lipidos: 8,
        carbohidratos: 0,
      },
    },

    {
      nombre: 'Leche descremada',
      equivalente: {
        kcal: 95,
        proteinas: 9,
        lipidos: 2,
        carbohidratos: 12,
      },
    },
    {
      nombre: 'Leche semidescremada',
      equivalente: {
        kcal: 110,
        proteinas: 9,
        lipidos: 4,
        carbohidratos: 12,
      },
    },
    {
      nombre: 'Leche entera',
      equivalente: {
        kcal: 150,
        proteinas: 9,
        lipidos: 8,
        carbohidratos: 12,
      },
    },
    {
      nombre: 'Leche con azúcar',
      equivalente: {
        kcal: 200,
        proteinas: 8,
        lipidos: 5,
        carbohidratos: 30,
      },
    },

    {
      nombre: 'Aceites y grasas sin proteína',
      equivalente: {
        kcal: 45,
        proteinas: 0,
        lipidos: 5,
        carbohidratos: 0,
      },
    },
    {
      nombre: 'Aceites y grasas con proteína',
      equivalente: {
        kcal: 70,
        proteinas: 3,
        lipidos: 5,
        carbohidratos: 3,
      },
    },

    {
      nombre: 'Azucares sin grasa',
      equivalente: {
        kcal: 40,
        proteinas: 0,
        lipidos: 0,
        carbohidratos: 10,
      },
    },
    {
      nombre: 'Azucares con grasa',
      equivalente: {
        kcal: 85,
        proteinas: 0,
        lipidos: 5,
        carbohidratos: 10,
      },
    },
    {
      nombre: 'Grasa con proteína',
      equivalente: {
        kcal: 45,
        proteinas: 0,
        lipidos: 5,
        carbohidratos: 0,
      },
    },
    {
      nombre: 'Grasa sin proteína',
      equivalente: {
        kcal: 70,
        proteinas: 3,
        lipidos: 5,
        carbohidratos: 3,
      },
    },
    {
      nombre: 'Suplemento de proteína',
      equivalente: {
        kcal: 400,
        proteinas: 80,
        lipidos: 7,
        carbohidratos: 7,
      },
    },
    {
      nombre: 'Bebidas alcohólicas',
      equivalente: {
        kcal: 140,
        proteinas: 0,
        lipidos: 0,
        carbohidratos: 20,
      },
    },
  ],
};

export default equivalentes;
