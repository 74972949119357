/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { gebAtom, getAtom } from '../../../states/atom';
import { ActividadLigeraType, ActividadModeradaType, ActividadIntensaType } from '../../../types/actividadesTypes';

import './calculadora.css';

const Calculadora = () => {
  //States del formato
  const [peso, setPeso] = useState('');
  const [altura, setAltura] = useState('');
  const [edad, setEdad] = useState('');
  const [masculino, setMasculino] = useState(false);
  const [femenino, setFemenino] = useState(false);
  const [actividadLigeraHoras, setLigeraHoras] = useState('');
  const [actividadModeradaHoras, setModeradaHoras] = useState('');
  const [actividadIntensaHoras, setIntensaHoras] = useState('');
  //States de Gasto Energético Basal y Total
  const [geb, setGeb] = useRecoilState(gebAtom);
  const [get, setGet] = useRecoilState(getAtom);
  //States de Modal Views
  const [isGebModalOpen, setIsGebModalOpen] = useState(false);
  const [isLigeraEjemplosOpen, setIsLigeraEjemplosOpen] = useState(false);
  const [isModeradaEjemplosOpen, setIsModeradaEjemplosOpen] = useState(false);
  const [isIntensaEjemplosOpen, setIsIntensaEjemplosOpen] = useState(false);
  // Error States
  const [pesoError, setPesoError] = useState(false);
  const [edadError, setEdadError] = useState(false);
  const [alturaError, setAlturaError] = useState(false);
  const [stringError, setStringError] = useState(false);

  function handleSubmit(event: any) {
    event.preventDefault();
    let newGeb = 0;
    let newGet = 0;

    // Parse the input values to numbers
    const pesoValue = Number(peso);
    const edadValue = Number(edad);
    const alturaValue = Number(altura);
    // Check if the parsed values are valid numbers
    if (isNaN(pesoValue) || isNaN(edadValue) || isNaN(alturaValue)) {
      console.error('Please enter valid numeric values for peso, edad, and altura.');
      setStringError(true);
      return;
    }

    //Manejar los errores en el formato
    if (pesoValue > 700 || pesoValue < 10) {
      setPesoError(true);
      return;
    } else {
      setPesoError(false);
    }

    if (edadValue > 120 || edadValue < 1) {
      setEdadError(true);
      return;
    } else {
      setEdadError(false);
    }

    if (alturaValue > 260 || alturaValue < 10) {
      setAlturaError(true);
      return;
    } else {
      setAlturaError(false);
    }

    //Calculo de Gasto Energético Basal
    if (masculino) {
      newGeb = 66.5 + 13.75 * pesoValue + 5.003 * alturaValue - 6.775 * edadValue;
    } else if (femenino) {
      newGeb = 655.1 + 9.563 * pesoValue + 1.85 * alturaValue - 4.676 * edadValue;
    }

    //Calculo de Gasto Energético Total
    const horasReposo =
      24 - (Number(actividadLigeraHoras) + Number(actividadModeradaHoras) + Number(actividadIntensaHoras));
    const actividadReposo = 1.25 * (newGeb / 24) * horasReposo;
    const actividadLigera = 2.5 * (newGeb / 24) * Number(actividadLigeraHoras);
    const actividadModerada = 5 * (newGeb / 24) * Number(actividadModeradaHoras);
    const actividadIntensa = 7 * (newGeb / 24) * Number(actividadIntensaHoras);
    newGet = actividadReposo + actividadLigera + actividadModerada + actividadIntensa + newGeb * 0.1;

    setGet(Math.round(newGet).toLocaleString());
    setGeb(Math.round(newGeb).toLocaleString());
    setIsGebModalOpen(true);
    setStringError(false);
    event.target.reset();
  }

  const actividadLigeraTypes = [ActividadLigeraType.Caminar, ActividadLigeraType.Compras, ActividadLigeraType.Limpar];
  const actividadModeradaTypes = [ActividadModeradaType.Correr, ActividadModeradaType.Gimnasio];
  const actividadIntensaTypes = [ActividadIntensaType.Futbol, ActividadIntensaType.Nadar];

  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <div style={{ maxWidth: '400px' }}>
          <Card className="d-flex align-items-center justify-content-center flex-column shadow-lg ">
            <Card.Body>
              <h2 className="text-center">Calculadora</h2>
              <h6 className="text-center">Gasto Energético Basal y Total</h6>

              <hr />
              <Form onSubmit={handleSubmit}>
                <Form.Group id="gender">
                  <Form.Label>Genero</Form.Label>
                  <div className="d-flex justify-content-start">
                    <Form.Check
                      type="radio"
                      label="Masculino"
                      name="gender"
                      value="masculino"
                      onClick={() => setMasculino(true)}
                      required
                    />

                    <Form.Check
                      type="radio"
                      label="Femenino"
                      name="gender"
                      value="femenino"
                      className="femenino-label"
                      required
                      onClick={() => setFemenino(true)}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-2" id="peso">
                  <Form.Label>Peso</Form.Label>
                  <Form.Control
                    type="peso"
                    placeholder="kg..."
                    onChange={(event) => setPeso(event.target.value)}
                    required
                  />
                  {pesoError && (
                    <div>
                      <p className="error-message">Error! Peso tiene que ser entre 10 y 700 Kilograms.</p>
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mb-2 " id="altura">
                  <Form.Label>Estatura</Form.Label>
                  <Form.Control
                    type="altura"
                    placeholder="cm..."
                    onChange={(event) => setAltura(event.target.value)}
                    required
                  />
                  {alturaError && (
                    <div>
                      <p className="error-message">Error! Altura tiene que ser entre 10 y 260 cm.</p>
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="mb-2 " id="edad">
                  <Form.Label>Edad</Form.Label>
                  <Form.Control
                    min="1"
                    max="125"
                    type="edad"
                    onChange={(event) => setEdad(event.target.value)}
                    required
                  />
                </Form.Group>
                {edadError && (
                  <div>
                    <p className="error-message">Error! Edad tiene que ser entre 1 y 120.</p>
                  </div>
                )}
                <h4 className="mt-5">Horas de Actividad Fisíca por Dia</h4>
                <hr />
                <Form.Group>
                  <div className="d-flex justify-content-between">
                    <Form.Label className="actividad-label">Actividad Fisíca Ligera </Form.Label>
                    <a href="#" className="question-mark" onClick={() => setIsLigeraEjemplosOpen(true)}>
                      ?
                    </a>
                  </div>
                  <Form.Control
                    onChange={(event) => setLigeraHoras(event.target.value)}
                    type="number"
                    placeholder="0"
                    min="0"
                    max="24"
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mt-3">
                  <div className="d-flex justify-content-between">
                    <Form.Label className="actividad-label">Actividad Fisíca Moderata</Form.Label>
                    <a href="#" className="question-mark" onClick={() => setIsModeradaEjemplosOpen(true)}>
                      ?
                    </a>
                  </div>
                  <Form.Control
                    onChange={(event) => setModeradaHoras(event.target.value)}
                    type="number"
                    placeholder="0"
                    min="0"
                    max="24"
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mt-3">
                  <div className="d-flex justify-content-between">
                    <Form.Label>Actividad Fisíca Intensa</Form.Label>
                    <a href="#" className="question-mark" onClick={() => setIsIntensaEjemplosOpen(true)}>
                      ?
                    </a>
                  </div>
                  <Form.Control
                    onChange={(event) => setIntensaHoras(event.target.value)}
                    type="number"
                    placeholder="0"
                    min="0"
                    max="24"
                  ></Form.Control>
                </Form.Group>
                <div className="text-center">
                  <Button className="w-75 mt-3 text-center" type="submit">
                    Calcular
                  </Button>
                </div>
              </Form>
              {(edadError || alturaError || pesoError) && (
                <div>
                  {' '}
                  <p className="error-message text-center mt-1">
                    Hubo un problema con tu calculo. Por favor, revisa los campos de arriba.
                  </p>
                </div>
              )}
              {stringError && (
                <div>
                  {' '}
                  <p className="error-message text-center mt-1">
                    Hubo un problema con tu calculo. Tienes que usar numeros en los campos de arriba.
                  </p>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </Container>
      {isGebModalOpen && (
        <div className="modal fade show d-block" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Resultados</h5>
                <span aria-hidden="true" onClick={() => setIsGebModalOpen(false)} style={{ cursor: 'pointer' }}>
                  X
                </span>
              </div>
              <div className="modal-body">
                <p>
                  <b>Gasto Energético Basal:</b> {geb} calorias
                </p>
                <p>
                  <b>Gasto Energético Total:</b> {get} calorias
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setIsGebModalOpen(false)}>
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setIsGebModalOpen(false);
                  }}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isLigeraEjemplosOpen && (
        <div className="modal fade show d-block" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ejemplos de Actividad Ligero</h5>
                <span aria-hidden="true" onClick={() => setIsLigeraEjemplosOpen(false)} style={{ cursor: 'pointer' }}>
                  X
                </span>
              </div>
              <div className="modal-body">
                <ul>
                  {actividadLigeraTypes.map((actividad) => {
                    return <li key={`${actividad}`}>{actividad}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModeradaEjemplosOpen && (
        <div className="modal fade show d-block" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ejemplos de Actividad Moderada</h5>
                <span aria-hidden="true" onClick={() => setIsModeradaEjemplosOpen(false)} style={{ cursor: 'pointer' }}>
                  X
                </span>
              </div>
              <div className="modal-body">
                <ul>
                  {actividadModeradaTypes.map((actividad) => {
                    return <li key={`${actividad}`}>{actividad}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {isIntensaEjemplosOpen && (
        <div className="modal fade show d-block" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ejemplos de Actividad Moderada</h5>
                <span aria-hidden="true" onClick={() => setIsIntensaEjemplosOpen(false)} style={{ cursor: 'pointer' }}>
                  X
                </span>
              </div>
              <div className="modal-body">
                <ul>
                  {actividadIntensaTypes.map((actividad) => {
                    return <li key={`${actividad}`}>{actividad}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calculadora;
