import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import Button from '@app/components/pages/sme/ComponentesSME/Button';
import Table from '@app/components/pages/sme/ComponentesSME/Table';
import ButtonPrint from '@app/components/pages/sme/ComponentesSME/ButtonPrint';

const SMEPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
      <PageTitle>{t('SME Page')}</PageTitle>
      <Table />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button buttonText="Guardar" />
        <div style={{ marginLeft: '300px' }}/> 
        <ButtonPrint buttonText="Imprimir Reporte" />
      </div>
    </div>
  );
};

export default SMEPage;
